import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Script from "react-inline-script"

class ThankYouPage extends React.Component {
  render() {
    const siteTitle = "Thank You"
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={`Thank You!`}>
        <SEO title={siteTitle} description={siteDescription} />
        <Script
          type="text/javascript"
          async
          defer
          src="https://www.googletagmanager.com/gtag/js?id=G-BG4XSGSXK2"
        ></Script>
        <Script type="text/javascript" async defer>
          window.dataLayer = window.dataLayer || []; function gtag()
          {`window.dataLayer.push(arguments);`}ß gtag('js', new Date());
          gtag('config', 'G-BG4XSGSXK2/OrExCL29oJEBELCC3Y8D');
        </Script>
        {/* Event snippet for Contact Us Form Submission conversion page  */}
        <Script>
          {`gtag('event', 'conversion', {'send_to': 'G-BG4XSGSXK2/UuvPCLTUgLwZELCC3Y8D'});`}
        </Script>

        <section className="component mt-5">
          <div className="container">
            <div className="row">
              <div className="mx-auto col-md-8 col-sm-10 text-center mt-5">
                <h3>
                  Thank you! We've received your message and will be in touch
                  soon.
                </h3>
                <div className="d-flex mt-2 justify-content-center">
                  <Link
                    aria-label="link"
                    to="/"
                    className="btn btn-primary mt-3"
                  >
                    RETURN HOME
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ThankYouPage

export const ThankYouAgencyQuery = graphql`
  query ThankYouAgencyQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
